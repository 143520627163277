<template>
    <div class="page-digital-comms-configuration container">
        <div class="row">
            <div class="col-6 col-tab-12">
                <div class="board">
                    <app-loader v-if="loading || processing.email"></app-loader>

                    <h2 class="heading">Email</h2>

                    <app-error v-model="errors.email.saving"></app-error>

                    <app-radio-buttons
                        v-model="email.using"
                        :items="email.options"

                        label="Sending using:"
                        :inline="true"

                        @select="errors.email.saving = null"
                    ></app-radio-buttons>

                    <div class="smtp" v-show="is_smtp">
                        <app-input v-model="email.smtp.hostname" type="text"     label="SMTP Server hostname" maxlength="100" :required="true" :error="errors.email.hostname" @change="errors.email.hostname = null" :disabled="processing.email"></app-input>
                        <app-input v-model="email.smtp.port"     type="tel"      label="SMTP Server port"     maxlength="5"   :required="true" :error="errors.email.port"     @change="errors.email.port = null"     :disabled="processing.email"></app-input>
                        <app-input v-model="email.smtp.username" type="text"     label="SMTP Username"        maxlength="64"  :required="true" :error="errors.email.username" @change="errors.email.username = null" :disabled="processing.email"></app-input>
                        <app-input v-model="email.smtp.password" type="password" label="SMTP Password"        maxlength="64"  :required="true" :error="errors.email.password" @change="errors.email.password = null" :disabled="processing.email"></app-input>
                    </div>

                    <button class="btn btn-small btn-primary" :disabled="!is_email_changed || processing.email" @click="saveEmail">Save</button>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <app-loader v-if="loading || processing.sms"></app-loader>

                    <h2 class="heading">SMS</h2>

                    <app-error v-model="errors.sms.saving"></app-error>

                    <app-radio-buttons
                        v-model="sms.using"
                        :items="sms.options"

                        label="Sending using:"
                        :inline="true"

                        @select="errors.sms.saving = null"
                    ></app-radio-buttons>

                    <button class="btn btn-small btn-primary" :disabled="!is_sms_changed || processing.sms" @click="saveSMS">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appRadioButtons from '@/components/app-radio-buttons'

import errMessage from '@/helpers/errMessage'
import SMTPValidator from '@/validators/smtp-validator'

export default {
    components: {
        appLoader,
        appError,

        appInput,
        appRadioButtons,
    },

    data() {
        return {
            email: {
                using: null,
                smtp: {
                    hostname: '',
                    port: '',
                    username: '',
                    password: '',
                },
                options: {
                    naas: {
                        value: 'naas',
                        label: 'NaaS Platform',
                    },
                    smtp: {
                        value: 'smtp',
                        label: 'Custom SMTP Server',
                    },
                },
            },

            sms: {
                using: null,
                options: {
                    naas: {
                        value: 'naas',
                        label: 'NaaS Platform',
                    },
                    elks: {
                        value: 'elks',
                        label: '46ELKS',
                        disabled: true,
                    },
                    twilio: {
                        value: 'twilio',
                        label: 'Twilio',
                        disabled: true,
                    },
                },
            },

            original: {
                email: {
                    using: null,
                    smtp: {
                        hostname: '',
                        port: '',
                        username: '',
                        password: '',
                    },
                },

                sms: {
                    using: null,
                }
            },

            errors: {
                email: {},
                sms: {},
            },

            loading: false,
            processing: {
                email: false,
                sms: false,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadConfiguration()
        },

        loadConfiguration() {
            this.loading = true

            this.$store.dispatch('getDigitalCommsConfiguration').then(({email, sms}) => {
                this.initEmail(email)
                this.initSMS(sms)

                this.loading = false
            }).catch(error => {
                console.error('Error getDigitalCommsConfiguration', error)
                console.dir(error)

                this.loading = false
            })
        },

        initEmail(email) {
            this.email.using = email.Using
            this.email.smtp = {
                hostname: email.Hostname,
                port:     email.Port,
                username: email.Username,
                password: ''
            }

            this.original.email.using = this.email.using
            this.original.email.smtp = {...this.email.smtp}
        },
        initSMS(sms) {
            this.sms.using = sms.Using

            this.original.sms.using = this.sms.using
        },
        
        saveEmail() {
            if (this.validation('email')) {
                this.processing.email = true

                const payload = {
                    Using: this.email.using,
                    ...(this.is_smtp ? {
                        ...payload,
                        Hostname: this.email.smtp.hostname,
                        Port:     this.email.smtp.port,
                        Username: this.email.smtp.username,
                        Password: this.email.smtp.password,
                    } : {})
                }

                this.$store.dispatch('updateDigitalCommsConfiguration', { email: payload }).then(({email}) => {
                    this.initEmail(email)

                    this.processing.email = false
                }).catch(error => {
                    this.errors.email = errMessage(error)

                    this.processing.email = false
                })
            }
        },

        saveSMS() {
            if (this.validation('sms')) {
                this.processing.sms = true

                const payload = {
                    Using: this.sms.using,
                }

                this.$store.dispatch('updateDigitalCommsConfiguration', { sms: payload }).then(({sms}) => {
                    this.initSMS(sms)

                    this.processing.sms = false
                }).catch(error => {
                    this.errors.sms = errMessage(error)

                    this.processing.sms = false
                })
            }
        },

        validation(target) {
            let values = null
            let Validator = null

            switch (target) {
                case 'email': {
                    if (this.is_smtp) {
                        values = {...this.email.smtp}
                        Validator = SMTPValidator
                    }
                } break
            }

            if (values && Validator) {
                this.errors[ target ] = {}

                for (const key in values) {
                    if (Validator.isRuleExists(key)) {
                        if (Validator.isInvalid(key, values[key])) {
                            this.$set(this.errors[ target ], key, Validator.getError())
                        }
                    }
                }
            }

            for (const key in this.errors[ target ]) {
                if (!this.errors[ target ][key]) {
                    this.$delete(this.errors[ target ], key)
                }
            }
            
            return !Object.keys(this.errors[ target ]).length
        },
    },

    computed: {
        is_smtp() {
            return this.email.using == 'smtp'
        },

        is_email_changed() {
            return this.email.using != this.original.email.using
                || (
                    this.is_smtp
                    && (
                        this.email.smtp.hostname != this.original.email.smtp.hostname
                        || this.email.smtp.port != this.original.email.smtp.port
                        || this.email.smtp.username != this.original.email.smtp.username
                        || this.email.smtp.password != this.original.email.smtp.password        
                    )
                )
        },
        is_sms_changed() {
            return this.sms.using != this.original.sms.using
        },
    },
}
</script>

<style lang="scss">
.page-digital-comms-configuration {
    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-top: 16px;
        }

        .app-radio-buttons {
            margin: 24px 0 0;
        }

        .smtp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: -6px -15px 0;

            .app-input {
                margin: 30px 15px 0;
                width: calc(50% - 30px);
            }
        }

        &>.btn {
            margin-top: 30px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-configuration {
        .col-tab-12 {
            margin-bottom: 30px;
        }

        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-configuration {
        .col-tab-12 {
            margin-bottom: 15px;
        }

        .board {
            padding: 16px 8px 8px;

            .smtp {
                margin: 12px 0 0;

                .app-input {
                    width: 100%;
                    margin: 12px 0 0;
                }
            }
            
            &>.btn {
                max-width: 100%;
            }
        }
    }
}
</style>