import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    hostname: {
        name: 'Hostname',
        required: true,
        format: /^([a-zA-Z][\w-]*(\.[a-zA-Z][\w\d-]*)+)|((\d{1,3}\.){3}\d{1,3})$/,
    },
    port: {
        name: 'Port',
        required: true,
        format: /^\d{2,5}$/,
        custom_message: 'Port should contain only 2-5 digits'
    },
    username: {
        name: 'Username',
        required: true,
        format: /^\w{1,64}$/,
    },
    password: {
        name: 'Password',
        required: true,
        customPasswordCheck: true,
    },
})